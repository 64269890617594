window.addEventListener('load', function() {
  if (window.matchMedia("(min-width: 960px)").matches) {
    window.addEventListener('scroll', function() {
      const header = document.querySelector('header');
      if (window.scrollY > 100) {
        header.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
      } else {
        header.style.backgroundColor = 'transparent';
      }
    });
  }
});