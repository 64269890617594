document.addEventListener('DOMContentLoaded', function () {
  const accordions = document.querySelectorAll('.js-accordion');

  accordions.forEach(accordionEl => {
    const trigger = accordionEl.querySelector('.js-accordion-trigger');

    trigger.addEventListener('click', () => {
      accordionEl.classList.toggle('js-accordion-opened');
    });
  })
});
