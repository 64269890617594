const DropMenu = (() => {
  const dropButton = document.querySelector("#dropButton");
  const dropContent = document.querySelector("#dropContent");
  const menu = document.querySelector(".header__left");
  const logo = document.querySelector(".logo");
  const menuList = document.querySelector(".menu__list");
  const menuListLink = document.querySelectorAll(".menu__list-link");
  let isOpen = false;

  const open = () => {
      dropContent.classList.add("show");
      menu.classList.add("active");
      dropButton.classList.add("close");
      logo.classList.add("hidden");
      menuList.classList.add("show");

      isOpen = true;
  }

    const close = () => {
        dropContent.classList.remove("show");
        menu.classList.remove("active");
        dropButton.classList.remove("close");
        logo.classList.remove("hidden");
        menuList.classList.remove("show");

        isOpen = false;
    }

  const toggle = () => {
      isOpen ? close() : open();
  }

  dropButton.addEventListener('click', toggle);

  menuListLink.forEach(link => {
      link.addEventListener("click", () => {
          if (isOpen) {
              close();
          }
      });
  });

  return {
      toggle,
      open,
      close,
  }
})();